import Layout from '../../components/Layout'
import React from 'react'
import clsx from 'clsx'
import { signIn, useSession } from 'next-auth/react'

type Props = {
  className?: string
}

export const AdminDashboard: React.FC<Props> = ({ className }) => {
  const { data: session, status } = useSession({
    required: true,
    onUnauthenticated() {
      signIn()
    },
  })
  return (
    <Layout
      className={clsx('admin-dashboard', className)}
      loading={status === 'loading'}
    >
      <div className="min-h-screen">
        <div className="panel mb-4 border-solid border-grey-light rounded border shadow-sm">
          <div className="heading bg-grey-lighter px-2 py-3 border-solid border-grey-light border-b">
            <span></span>
            <span>Dashboad</span>
            <div>
              <small>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
                sit amet maximus. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit. Proin sit amet maximus. Lorem ipsum dolor sit
                amet, consectetur adipiscing elit. Proin sit amet maximus. Lorem
                ipsum dolor sit amet, consectetur adipiscing elit. Proin sit
                amet maximus{' '}
              </small>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{``}</style>
    </Layout>
  )
}
