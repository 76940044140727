import Layout from '../../../components/Layout'
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useSession } from 'next-auth/react'
import useSWR from 'swr'
import { safeFetch } from 'fetcher'
import { useRouter } from 'next/router'
import Button, { ButtonKind, ButtonSize } from '../../../components/Button'
import Icon from '../../../components/Icon'
import useNotifications from '../../../hooks/useNotification'

type Props = {
  className?: string
  id: any
}

export const RoleEdit: React.FC<Props> = ({ className, id }) => {
  const router = useRouter()
  const notifier = useNotifications()
  const [getRole, setRole] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { id: roleId } = router.query
  const { data: session } = useSession()
  const [isFetchingRole, setIsFetchingRole] = useState<boolean>(false)

  useEffect(() => {
    if (session) {
      if (!session.user?.roles?.includes('admin')) {
        router.replace('/dashboard')
      }
    }
  })

  const { data: roles, mutate: refreshRoles } = useSWR(
    session ? `/api/mad-mint/role?id=${roleId}` : null
  )

  const handleRoleChange = async (e: any) => {
    setError('')
    setRole(e.target.value)
  }

  const handleRoleUpdate = async (e: any) => {
    e.preventDefault()
    let role = getRole.trim()
    if (role === '') {
      setError('Role cannot be empty!')
      setRole(roles.roleName)
      return
    }
    setLoading(true)
    try {
      let res = await safeFetch('/api/mad-mint/role', {
        method: 'PUT',
        body: {
          id: parseInt(id),
          name: role,
        },
      })
      if (res.status === 200) {
        notifier({ message: res.message, style: 'success' })
        setRole('')
        router.push('/admin/role')
      } else {
        notifier({
          message: res.error.info.error,
          style: 'error',
        })
      }
      refreshRoles()
    } catch (e) {
      if (e) {
        notifier({
          message: 'Server Error, Please try Again!',
          style: 'error',
        })
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (roles) {
      setRole(roles?.roleName)
    }
  }, [roles])

  return (
    <Layout
      className={clsx('admin-dashboard', className)}
      loading={isFetchingRole}
    >
      <div className="min-h-screen">
        <div className="panel mb-4 border-solid border-grey-light rounded border shadow-sm">
          <div className="heading bg-grey-lighter px-2 py-3 border-solid border-grey-light border-b">
            <h1 className="text-base font-medium">
              Edit Role - <b>{roles?.roleName}</b>
            </h1>
            <form className="w-full">
              <div className="flex flex-wrap -mx-3 mt-4 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <input
                    className={`appearance-none block w-full border py-3 px-4 mb-1 leading-tight focus:outline-none focus:border-gray-500 `}
                    type="text"
                    name="role"
                    required
                    placeholder="Enter Role"
                    onChange={handleRoleChange}
                    value={getRole}
                  />
                  {error === '' || (
                    <small className="text-red-500 flex items-center">
                      <Icon icon="warning" className="mr-1" size={10}></Icon>
                      {error}
                    </small>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <Button
                    kind={ButtonKind.primaryCta}
                    size={ButtonSize.medium}
                    showLoader={isLoading}
                    disabled={isLoading}
                    onClick={handleRoleUpdate}
                    type="submit"
                  >
                    Update Role
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <style jsx>{``}</style>
    </Layout>
  )
}
