import Layout from '../../components/Layout'
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useSession } from 'next-auth/react'
import useSWR from 'swr'
import { safeFetch } from 'fetcher'
import { useRouter } from 'next/router'
import Button, { ButtonKind, ButtonSize } from '../../components/Button'
import Icon from '../../components/Icon'
import useNotifications from '../../hooks/useNotification'

type Props = {
  className?: string
}

type ErrorProps = {
  role: string
}

export const Roles: React.FC<Props> = ({ className }) => {
  const router = useRouter()
  const [getRole, setRole] = useState('')
  const { data: session } = useSession()
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const notifier = useNotifications()
  useEffect(() => {
    if (session) {
      if (!session.user?.roles?.includes('admin')) {
        router.replace('/dashboard')
      }
    }
  })

  const { data: roles, mutate } = useSWR(session ? `/api/mad-mint/role` : null)

  const handleRoleChange = async (e: any) => {
    setError('')
    let role = e.target.value
    let exists = roles.filter(
      (item: { roleName: any }) => item.roleName === role.trim()
    )
    if (exists.length > 0) {
      setError('Role Already Exists!')
    }
    setRole(e.target.value)
    if (
      roles.find(
        (role: any) =>
          role.roleName.toLowerCase() === e.target.value.toLowerCase()
      )
    ) {
      setError('Role already exist')
    } else {
      error && setError('')
    }
  }

  const handleRole = async (e: any) => {
    e.preventDefault()
    let role = getRole.trim()
    if (role === '') {
      setError('Role cannot be empty!')
      setRole('')
      return
    }
    setLoading(true)
    try {
      let res = await safeFetch('/api/mad-mint/role', {
        body: {
          name: role,
        },
      })
      if (res.status === 200) {
        notifier({ message: res.message, style: 'success' })
        setRole('')
      } else {
        notifier({
          message: res.error.info.error,
          style: 'error',
        })
      }
      mutate()
    } catch (e) {
      if (e) {
        notifier({
          message: 'Server Error, Please try Again!',
          style: 'error',
        })
      }
    }
    setLoading(false)
  }

  const handleRoleDelete = async (id: number) => {
    let res = await safeFetch('/api/mad-mint/role', {
      method: 'DELETE',
      body: {
        id: id,
      },
    })
    if (res.error) {
      notifier({
        message: res.error?.info?.error,
        style: 'error',
      })
    }
    if (res.message) {
      notifier({
        message: res.message,
        style: 'success',
      })
    }
    mutate()
  }

  return (
    <Layout className={clsx('admin-dashboard', className)}>
      <div className="min-h-screen">
        <div className="panel mb-4 border-solid border-grey-light rounded border shadow-sm">
          <div className="heading bg-grey-lighter px-2 py-3 border-solid border-grey-light border-b">
            <h1 className="text-base font-medium">Roles</h1>
            <form className="w-full">
              <div className="flex flex-wrap -mx-3 mt-4 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <input
                    className={`appearance-none block w-full border py-3 px-4 mb-1 leading-tight focus:outline-none focus:border-gray-500 `}
                    type="text"
                    name="role"
                    required
                    value={getRole}
                    placeholder="Enter Role"
                    onChange={handleRoleChange}
                  />
                  {error === '' || (
                    <small className="text-red-500 flex items-center">
                      <Icon icon="warning" className="mr-1" size={10}></Icon>
                      {error}
                    </small>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                  <Button
                    kind={ButtonKind.primaryCta}
                    size={ButtonSize.medium}
                    showLoader={isLoading}
                    disabled={isLoading || error !== ''}
                    onClick={handleRole}
                    type="submit"
                  >
                    Add Role
                  </Button>
                </div>
              </div>
            </form>
            <div>
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-x-auto">
                      <table className="min-w-full">
                        <thead className="border-b">
                          <tr>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {roles?.map((role: any, index: number) => (
                            <tr className="border-b" key={index}>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {role.id}
                              </td>
                              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                {role.roleName}
                              </td>
                              <td
                                className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap"
                                id={role.id}
                              >
                                <button
                                  onClick={() => handleRoleDelete(role.id)}
                                  className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded mr-2"
                                >
                                  Delete
                                </button>

                                <button
                                  onClick={() => {
                                    router.push({
                                      pathname: '/admin/role/[id]',
                                      query: { id: role.id },
                                    })
                                  }}
                                  className="bg-sky-400 hover:bg-sky-600 text-white py-2 px-4 rounded"
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{``}</style>
    </Layout>
  )
}
