import React from 'react'
import ProjectListing from '../../components/Projects'
import Layout from '../../components/Layout'

type Props = {
  className?: string
}

export const ProjectListingPage: React.FC<Props> = ({ className }) => {
  return (
    <Layout className={className}>
      <ProjectListing />
    </Layout>
  )
}
