/* eslint-disable react/no-unescaped-entities */
import { useSession } from 'next-auth/react'
import Layout from '../components/Layout'
import React from 'react'
import clsx from 'clsx'

type Props = {
  className?: string
}

export const Profile: React.FC<Props> = ({ className }) => {
  const { data: session, status } = useSession()

  return (
    <Layout
      className={clsx('login-page', className)}
      loading={status === 'loading'}
    >
      <div className="min-h-screen">
        <main className="flex-1">
          <section>
            <div className="panel mb-4 border-solid border-grey-light rounded border shadow-sm">
              <div className="heading bg-grey-lighter px-2 py-3 border-solid border-grey-light border-b">
                <span></span>
                <span>Email</span>
                <div>
                  <small className="text-gray-500 text-xs">
                    Get alerts about lists you've joined and updates from
                    PREMINT.{' '}
                  </small>
                </div>
              </div>
              <div className="desciption p-3">{session?.user?.email}</div>
            </div>
          </section>
        </main>
      </div>
    </Layout>
  )
}
