import Layout from '../../../components/Layout'
import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import { useSession } from 'next-auth/react'
import useSWR from 'swr'
import { safeFetch } from 'fetcher'
import { useRouter } from 'next/router'
import Button, { ButtonKind, ButtonSize } from '../../../components/Button'
import '../../../../auth/types'
import useNotifications from '../../../hooks/useNotification'

type Props = {
  className?: string
  id: any
}

export const UserEdit: React.FC<Props> = ({ className, id }) => {
  const router = useRouter()
  const [getRole, setRole] = useState<number[]>([])
  const [isLoading, setLoading] = useState(true)
  const notifier = useNotifications()

  const { data: session } = useSession()

  useEffect(() => {
    if (session) {
      if (!session.user?.roles?.includes('admin')) {
        router.replace('/dashboard')
      }
    }
  })

  const { data: roles, mutate: roleRefresh } = useSWR(
    session ? '/api/mad-mint/role' : null
  )
  const { data: UserData, mutate: userRefresh } = useSWR(
    session ? `/api/mad-mint/user?id=${id}` : null
  )

  useEffect(() => {
    if (UserData?.userRolesIdArray) {
      setRole(UserData.userRolesIdArray)
      setLoading(false)
    }
    roleRefresh()
    userRefresh()
  }, [UserData])

  const onChangeCheckBox = async (e: any) => {
    const { value, checked } = e.target
    const checkboxes: HTMLInputElement[] = Array.from(
      document.querySelectorAll('input[name="role"]:checked')
    )
    const values: number[] = []
    if (checkboxes.length > 0) {
      checkboxes.forEach((checkbox) => {
        values.push(Number(checkbox.value))
      })
    }
    if (checked == false) {
      const index = values.indexOf(value)
      if (index > -1) {
        values.splice(index, 1)
      }
    }
    setRole(values)
  }

  const handleUserUpdate = async (e: any) => {
    e.preventDefault()
    setLoading(true)
    try {
      let res = await safeFetch('/api/mad-mint/user', {
        method: 'PUT',
        body: {
          id: id,
          roles: getRole,
        },
      })
      if (res.status === 200) {
        notifier({ message: res.message, style: 'success' })
      } else {
        notifier({
          message: res.error.info.error,
          style: 'error',
        })
      }
      router.push('/admin/user')
      roleRefresh()
      userRefresh()
    } catch (e) {
      if (e) {
        notifier({
          message: 'Server Error, Please try Again!',
          style: 'error',
        })
      }
    }
    setLoading(false)
  }

  return (
    <Layout className={clsx('admin-dashboard', className)} loading={isLoading}>
      <div className="min-h-screen">
        <div className="panel mb-4 border-solid border-grey-light rounded border shadow-sm">
          <div className="heading bg-grey-lighter px-2 py-3 border-solid border-grey-light border-b">
            <h1 className="text-base font-medium">
              Roles - <b>{UserData?.userRolesNameArray.join(', ')}</b>
            </h1>
            <form>
              <div className="py-5">
                {roles?.map((role: any, index: any) => (
                  <div className="form-check">
                    <input
                      className="w-4 h-4 ml-4"
                      type="checkbox"
                      value={role.id}
                      id={role.id}
                      onClick={onChangeCheckBox}
                      defaultChecked={UserData?.userRolesIdArray?.includes(
                        role.id
                      )}
                      name="role"
                    />
                    <label
                      className="form-check-label inline-block text-gray-800 mb-2 pl-2"
                      htmlFor={role.id}
                    >
                      {role.roleName}
                    </label>
                  </div>
                ))}
              </div>

              <Button
                kind={ButtonKind.primaryCta}
                size={ButtonSize.medium}
                showLoader={isLoading}
                disabled={isLoading}
                onClick={handleUserUpdate}
              >
                Update User
              </Button>
            </form>
          </div>
        </div>
      </div>
      <style jsx>{``}</style>
    </Layout>
  )
}
