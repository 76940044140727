import Layout from '../../components/Layout'
import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { useSession } from 'next-auth/react'
import useSWR from 'swr'
import { useRouter } from 'next/router'
import Pagination from '../../components/Pagination'
import Icon from '../../components/Icon'
import Button, { ButtonKind, ButtonSize } from '../../components/Button'
import { safeFetch } from 'fetcher'
import useNotifications from '../../../../packages/mad-mint/hooks/useNotification'

type Props = {
  className?: string
}

export const UserListing: React.FC<Props> = ({ className }) => {
  const router = useRouter()
  const notifier = useNotifications()
  const { data: session, status } = useSession()
  const {
    query: { page },
  } = router
  const currentPage = Number(page || '1')
  const pageSize = 10
  const skip = (currentPage - 1) * pageSize
  const [searchText, setSearchText] = useState('')
  const [search, setSearch] = useState('')

  useEffect(() => {
    if (session) {
      if (!session.user?.roles?.includes('admin')) {
        router.replace('/dashboard')
      }
    }
  })

  const { data: users, mutate: refetchUsers } = useSWR(
    session
      ? `/api/mad-mint/user?skip=${skip}&limit=${pageSize}${
          search ? `&search=${search}` : ''
        }`
      : null
  )

  const handlePageNumberClick = (pageNo: number) => {
    router.push(`/admin/user?page=${pageNo}`)
  }

  const handleSearch = () => {
    setSearch(searchText)
    router.push(`/admin/user`)
  }

  const clearSearch = () => {
    setSearch('')
    setSearchText('')
    router.push(`/admin/user`)
  }

  useEffect(() => {
    if (!searchText) {
      setSearch('')
      router.push(`/admin/user`)
    }
  }, [searchText])

  const handleDeleteUser = async (user: any) => {
    const response = await safeFetch('/api/mad-mint/user', {
      body: { userId: user.id },
      method: 'DELETE',
    })

    if (response && response.status === 200) {
      refetchUsers()
      notifier({
        message: response?.message || 'User deleted successfully',
        style: 'success',
      })
    } else {
      notifier({
        message: response?.error || 'Unable to delete user',
        style: 'error',
      })
    }
  }

  return (
    <Layout
      className={clsx('admin-dashboard', className)}
      loading={status === 'loading'}
    >
      <div>
        <div className="panel mb-4 border-solid border-grey-light rounded border shadow-sm">
          <div className="heading bg-grey-lighter px-2 py-3 border-solid border-grey-light border-b">
            <div className="usersFilterActionHeader">
              <h1 className="text-base font-medium">Users</h1>
              <div className="usersFilterWrapper">
                <div className="relative mr-4">
                  <input
                    className={`block w-full border focus:outline-none focus:border-gray-500 usersSearchInput`}
                    id="search"
                    type="text"
                    placeholder="Search Users"
                    name="search"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                  {search && (
                    <Icon
                      icon="cross"
                      size={15}
                      className="clearSearchIcon"
                      onClick={clearSearch}
                    />
                  )}
                </div>
                <Button
                  kind={ButtonKind.primaryCta}
                  size={ButtonSize.medium}
                  showLoader={false}
                  disabled={false}
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </div>
            </div>
            <div>
              <div className="flex flex-col">
                <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="overflow-x-auto">
                      <table className="min-w-full">
                        <thead className="border-b">
                          <tr>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Role
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Email
                            </th>
                            <th
                              scope="col"
                              className="text-sm font-medium text-gray-900 px-6 py-4 text-left"
                            >
                              Actions
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {users?.allUsersWithRoles?.map(
                            (user: any, index: number) => (
                              <tr className="border-b" key={index}>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  <div>
                                    <ul className="list-disc list-inside">
                                      {user.role?.map(
                                        (value: any, key: number) => (
                                          <li key={key}>
                                            {value?.roles.roleName}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </div>
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  {user.email}
                                </td>
                                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                                  <button
                                    onClick={() => {
                                      router.push({
                                        pathname: '/admin/user/[id]',
                                        query: { id: user.id },
                                      })
                                    }}
                                    className="bg-sky-400 hover:bg-sky-600 text-white py-2 px-4 rounded mr-2"
                                  >
                                    Update User
                                  </button>
                                  <button
                                    onClick={() => handleDeleteUser(user)}
                                    className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded"
                                  >
                                    Delete User
                                  </button>
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="paginationWrapper">
          <Pagination
            totalCount={users?.totalUsersCount}
            pageSize={pageSize}
            currentPage={currentPage}
            onPageChange={handlePageNumberClick}
          />
        </div>
      </div>
      <style jsx>{`
        .usersFilterActionHeader {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
        }
        .usersFilterWrapper {
          display: flex;
          padding-top: 16px;
        }
        .usersSearchInput {
          line-height: 1.25;
          padding: 12px 40px 12px 12px;
        }
        .usersFilterWrapper :global(.clearSearchIcon) {
          position: absolute;
          top: 16px;
          right: 12px;
          cursor: pointer;
        }
        .paginationWrapper {
          display: flex;
          justify-content: center;
        }
        @media (min-width: 480px) {
          .usersFilterActionHeader {
            flex-direction: row;
          }
          .usersFilterWrapper {
            padding-top: 0px;
          }
          .paginationWrapper {
            justify-content: flex-end;
          }
        }
      `}</style>
    </Layout>
  )
}
