import React, { useState, useContext } from 'react'
import Head from 'next/head'
import MadMintThemeContext from '../contexts/MadMint'

type Props = {
  className?: string
}

export const VerifyRequest: React.FC<Props> = ({ className }) => {
  const { theme } = useContext(MadMintThemeContext)
  return (
    <div className="bg flex items-center justify-center h-screen">
      <div className="topwrapper flex items-center justify-center h-screen">
        <Head>
          <title>Check your email</title>
        </Head>
        <div className="wrapper text-center">
          <h1 className="text-white font-bold">Check your email</h1>
          <p className="py-3 text-white">
            A sign in link has been sent to your email address.
          </p>
        </div>
      </div>
      <style jsx>{`
        .topwrapper {
          background: ${theme.colors.black};
          width: 400px;
          height: 400px;
        }
        .wrapper {
          background: ${theme.colors.black};
          width: 300px;
          padding: 10px;
        }
        h1 {
          font-style: normal;
          font-weight: 700;
          font-size: 56px;
          line-height: 118%;
          margin-bottom: 15px;
        }
        .bg {
          background-image: linear-gradient(
            90deg,
            #010b19 0%,
            #010b19 35%,
            #010b19 55%,
            #6b32b6 100%
          );
        }
        @media screen and (max-width: 480px) {
          .topwrapper {
            padding: 10px;
            width: 300px;
            height: 300px;
          }
          .button {
            width: 250px;
          }
        }
      `}</style>
    </div>
  )
}
