import { useState } from 'react'
import useSWR from 'swr'
import Icon from '../../Icon'
import Button, { ButtonKind, ButtonSize } from '../../Button'

type Props = {
  slug: string
}

const ProjectEntries: React.FC<Props> = ({ slug }) => {
  const [isLoading, setLoading] = useState(false)
  const { data: project } = useSWR(
    slug ? `/api/mad-mint/projects/settings?slug=${slug}` : null
  )

  const { data: entries } = useSWR(
    project ? `/api/mad-mint/projects/entries?slug=${slug}` : null
  )

  const handleExport = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    var data: any = []
    setLoading(true)
    data[0] = [
      'Email',
      'Wallet Balance',
      'Wallet Address',
      'Twitter Id',
      'Discord Id',
      'Registered',
    ]
    entries.usersProjects.forEach((val: any, key: any) => {
        const wallet = entries?.projectWalletUsers?.find((i: any) => {
            return i.userId === val.user.id
        })
        const twitter = entries?.projectTwitterUsers?.find((i: any) => {
            return i.userId === val.user.id
        })
        const discord = entries?.projectDiscordUsers?.find((i: any) => {
            return i.userId === val.user.id
        })
        
        
      data[key + 1] = [
        val.user.email,
        wallet ? wallet.balance + 'ETH' : '0 ETH',
        wallet ? wallet.address : 'NULL',
        twitter ? twitter.twitterId : 'NULL',
        discord ? discord.discordName : 'NULL',
        val.status ? 'Yes' : 'No',
      ]
    })
    let csvContent = 'data:text/csv;charset=utf-8,'
    data.forEach((rowArray: any) => {
      let row = rowArray.join(',')
      csvContent += row + '\r\n'
    })
    var encodedUri = encodeURI(csvContent)
    window.open(encodedUri)
    setLoading(false)
  }

  return (
    <div className="projectEntriesWrapper">
      <div className="min-h-screen">
        <div className="w-full p-5 font-bold">Full List</div>
        <div className="md:hidden overflow-x-auto relative shadow-md">
          <table className="w-full border p-3 leading-tight" cellPadding={10}>
            <tbody>
              {entries?.usersProjects.map((val: any, key: any) => {
                const wallet = entries?.projectWalletUsers?.find((i: any) => {
                    return i.userId === val.user.id
                })
                const twitter = entries?.projectTwitterUsers?.find((i: any) => {
                    return i.userId === val.user.id
                })
                const discord = entries?.projectDiscordUsers?.find((i: any) => {
                    return i.userId === val.user.id
                })
                return (
                  <tr className="border p-3 leading-tight" key={key}>
                    <td>
                      <div>
                        <Icon icon="envelop" size={20} className="m-2" />
                        {val.user.email}
                      </div>
                      <div>
                        <Icon icon="wallet" size={20} className="m-2" />
                        {wallet ? ((wallet.balance || 0) + 'ETH') : null}
                      </div>
                      <div>
                        <Icon icon="wallet" size={20} className="m-2" />
                        {wallet ? wallet.address : 'NULL'}
                      </div>
                      <div className="truncate">
                        <Icon icon="twitter" size={20} className="m-2" />
                        {twitter ? twitter.twitterId : 'NULL'}
                      </div>
                      <div className="truncate">
                        <Icon icon="discord" size={20} className="m-2" />
                        {discord ? discord.discordName : 'NULL'}
                      </div>
                      <div>
                        <Icon icon="users" size={20} className="m-2" />
                        {val.status ? 'Yes' : 'No'}
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="hidden md:flex">
          <table className="w-full border p-3 leading-tight" cellPadding={10}>
            <thead>
              <tr>
                <th className="font-normal text-left">
                  <Icon icon="envelop" size={20} className="mr-2 mb-1" />
                  Email
                </th>
                <th className="font-normal text-left">
                  <Icon icon="wallet" size={20} className="mr-2 mb-1" />
                  Wallet{' '}
                  <div>
                    <small>Balance</small>
                  </div>
                </th>
                <th className="font-normal text-left">
                  <Icon icon="wallet" size={20} className="mr-2 mb-1" />
                  Wallet{' '}
                  <div>
                    <small>Address</small>
                  </div>
                </th>
                <th className="font-normal text-left">
                  <Icon icon="twitter" size={20} className="mr-2 mb-1" />
                  Twitter{' '}
                  <div>
                    <small>Twitter Id</small>
                  </div>
                </th>
                <th className="font-normal text-left">
                  <Icon icon="discord" size={20} className="mr-2 mb-1" />
                  Discord
                </th>
                <th className="font-normal text-left">
                  <Icon icon="users" size={20} className="mr-2 mb-1" />
                  Registered
                </th>
              </tr>
            </thead>
            <tbody>
              {entries?.usersProjects.map((val: any, key: any) => {
                const wallet = entries?.projectWalletUsers?.find((i: any) => {
                    return i.userId === val.user.id
                })
                const twitter = entries?.projectTwitterUsers?.find((i: any) => {
                    return i.userId === val.user.id
                })
                const discord = entries?.projectDiscordUsers?.find((i: any) => {
                    return i.userId === val.user.id
                })
                return (
                  <tr className="border p-3 leading-tight" key={key}>
                    <td>{val.user.email} </td>
                    <td>
                      {wallet ? ((wallet.balance || 0) + 'ETH') : null}
                    </td>
                    <td>
                      {wallet ? wallet.address : 'NULL'}
                    </td>
                    <td>{twitter ? twitter.twitterId : 'NULL'}</td>
                    <td>{discord ? discord.discordName : 'NULL'}</td>
                    <td>{val.status ? 'Yes' : 'No'}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <div className="p-2"></div>
        <div className="text-right">
          <Button
            kind={ButtonKind.primaryCta}
            size={ButtonSize.medium}
            showLoader={isLoading}
            disabled={isLoading}
            onClick={handleExport}
          >
            Export
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ProjectEntries
