import { useState, useEffect } from 'react'
import { useSession } from 'next-auth/react'
import useSWR from 'swr'
import Icon from '../Icon'
import { useRouter } from 'next/router'
import Pagination from '../Pagination'
import Button, { ButtonKind, ButtonSize } from '../Button'

type Props = {}

type ProjectListingProp = {
  projectDatails?: any
}

const ProjectListing: React.FC<Props> = ({}) => {
  const [isLoading, setLoading] = useState(false)
  const { data: session } = useSession()
  const router = useRouter()
  const {
    query: { page },
  } = router
  const currentPage = Number(page || '1')
  const pageSize = 10
  const skip = (currentPage - 1) * pageSize
  const [getProjects, setProjects] = useState([])
  const [searchText, setSearchText] = useState('')
  const [search, setSearch] = useState('')

  const { data: projects } = useSWR(
    session
      ? `/api/mad-mint/projects?skip=${skip}&limit=${pageSize}${
          search ? `&search=${search}` : ''
        }`
      : null
  )

  useEffect(() => {
    setLoading(true)
    if (projects && projects.data) {
      setProjects(projects.data)
      setLoading(false)
    }
  }, [projects])

  const handlePageNumberClick = (pageNo: number) => {
    router.push(`/projects?page=${pageNo}&limit=${pageSize}`)
  }

  const handleSearch = () => {
    setSearch(searchText)
    router.push(`/projects`)
  }

  const clearSearch = () => {
    setSearch('')
    setSearchText('')
    router.push(`/projects`)
  }

  useEffect(() => {
    if (!searchText) {
      setSearch('')
      router.push(`/projects`)
    }
  }, [searchText])

  return (
    <div className="projectListingWrapper">
      <div className="my-flex createProjectActionWrapper">
        <div className="inline-block text-base">
          These are all the project you've created.
        </div>
        <div className="sm:my-px-5 text-sm sm:text-base createProjectBtn">
          <a
            title="Create Project"
            className="bg-sky-400 hover:bg-sky-600 text-white py-2 px-2 sm:px-4 rounded"
            href="/projects/create"
          >
            <Icon icon="plus" size={15} className="mb-1 mr-1" /> Create Project
          </a>
        </div>
      </div>
      <div className="projectFilterWrapper">
        <div className="relative mr-4">
          <input
            className={`block w-full border focus:outline-none focus:border-gray-500 projectSearchInput`}
            id="search"
            type="text"
            placeholder="Search Projects"
            name="search"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {search && (
            <Icon
              icon="cross"
              size={15}
              className="clearSearchIcon"
              onClick={clearSearch}
            />
          )}
        </div>
        <Button
          kind={ButtonKind.primaryCta}
          size={ButtonSize.medium}
          showLoader={isLoading}
          disabled={isLoading}
          onClick={handleSearch}
        >
          Search
        </Button>
      </div>
      <div className="min-h-screen">
        <div className="flex">
          <div className="my-grid my-grid-cols-3 my-gap-4 w-full">
            {getProjects.length > 0 ? (
              <>
                {getProjects?.map((project: any, index: any) => {
                  return <ProjectPanel projectDatails={project} key={index} />
                })}
              </>
            ) : (
              <div>{isLoading ? 'Loading....' : 'No Projects Available'}</div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <Pagination
          totalCount={projects?.totalCount}
          pageSize={pageSize}
          currentPage={currentPage}
          onPageChange={handlePageNumberClick}
        />
      </div>
      <style jsx>
        {`
          .my-grid-cols-3 {
            grid-template-columns: repeat(3, minmax(0, 1fr));
          }
          .my-grid {
            display: grid;
          }
          .my-gap-4 {
            gap: 1rem;
          }
          .my-flex {
            display: flex;
          }
          .createProjectActionWrapper {
            justify-content: space-between;
          }
          .createProjectBtn {
            flex-shrink: 0;
          }
          .projectFilterWrapper {
            display: flex;
            justify-content: flex-end;
            margin-left: auto;
            padding-top: 20px;
          }
          .projectSearchInput {
            line-height: 1.25;
            padding: 12px 40px 12px 12px;
          }
          .projectFilterWrapper :global(.clearSearchIcon) {
            position: absolute;
            top: 16px;
            right: 12px;
            cursor: pointer;
          }
          @media (max-width: 1279px) {
            .my-grid-cols-3 {
              grid-template-columns: repeat(2, minmax(0, 1fr));
            }
          }
          @media (max-width: 474px) {
            .my-grid-cols-3 {
              grid-template-columns: repeat(1, minmax(0, 1fr));
            }
          }
        `}
      </style>
    </div>
  )
}

const ProjectPanel: React.FC<ProjectListingProp> = ({ projectDatails }) => {
  return (
    <>
      <div className="shadow-lg my-5 border border-gray-200 relative project-wrapper">
        <div>
          <div className="bg-slate-300 image-bg flex justify-center">
            {projectDatails?.logo && (
              <img src={projectDatails?.logo} alt={projectDatails?.title} />
            )}
          </div>
          <div className="p-5">
            <div className="font-bold text-xl mb-2 text-ellipsis">
              {projectDatails?.title}
            </div>
          </div>
        </div>
        <div className="p-5 my-mb-5">
          <span className="my-float-left">
            <a
              className="stretched-link"
              href={`/projects/${projectDatails?.slug}/settings`}
            >
              Read more
            </a>
          </span>
          <span className="my-float-right">
            {' '}
            <a href={`/projects/${projectDatails?.slug}/entries`}>
              {projectDatails?.usersProjects?.length} entries
            </a>
          </span>
        </div>
        <style jsx>
          {`
            .image-bg img {
              height: 200px;
            }
            .stretched-link::after {
              content: '';
              z-index: 10;
              position: absolute;
              top: 0px;
              right: 0px;
              bottom: 0px;
              left: 0px;
              background-color: transparent;
              pointer-events: auto;
            }
            .my-float-right {
              float: right;
              position: relative;
              z-index: 11;
            }
            .my-float-left {
              float: left;
            }
            .my-mb-5 {
              margin-bottom: 1.25rem;
            }
            .project-wrapper {
              min-width: 310px;
            }
            @media (max-width: 1023px) {
              .project-wrapper {
                min-width: 100%;
              }
            }
          `}
        </style>
      </div>
    </>
  )
}
export default ProjectListing
