import clsx from 'clsx'
import { useRouter } from 'next/router'
import React from 'react'
import { usePagination } from './usePagination'

type Props = {
  totalCount: number
  pageSize: number
  siblingCount?: number
  currentPage: number
  onPageChange: (pageNo: number) => void
}

const Pagination: React.FC<Props> = ({
  totalCount,
  pageSize,
  currentPage,
  siblingCount = 1,
  onPageChange,
}) => {
  const router = useRouter()
  const {
    query: { page },
  } = router

  let pageCount =
    totalCount % pageSize !== 0
      ? Math.trunc(totalCount / pageSize) + 1
      : totalCount / pageSize

  const handleClick = (e: any, pageNo: any) => {
    e.preventDefault()
    onPageChange(pageNo)
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  // Pagination is not needed in this case
  if (totalCount <= pageSize) {
    return null
  }

  const paginationRange: any = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  const pageBlock =
    paginationRange &&
    paginationRange.map((pageNumber: any, key: number) => {
      if (pageNumber === '...') {
        return (
          <li key={key} className="pagination-item dots">
            &#8230;
          </li>
        )
      }

      return (
        <li
          key={key}
          className={clsx('pagination-item', {
            activePage: currentPage
              ? pageNumber === Number(currentPage)
              : pageNumber === Number(page),
          })}
          onClick={(e) => {
            handleClick(e, pageNumber)
          }}
        >
          {pageNumber}
        </li>
      )
    })

  return (
    <>
      <div className="pagination">
        <div
          className={clsx('first-last', currentPage === 1 ? 'disable-arrow' : '')}
          onClick={(e) => {
            handleClick(e, currentPage - 1)
          }}
        >
          &lt;
        </div>
        <div className="pagination-item-list">{pageBlock}</div>
        <div
          className={clsx('first-last', currentPage === pageCount ? 'disable-arrow' : '')}
          onClick={(e) => {
            handleClick(e, currentPage + 1)
          }}
        >
          &gt;
        </div>
      </div>
      <style jsx>{`
        .pagination {
          display: flex;
        }
        .first-last {
          padding: 8px;
          box-sizing: border-box;
          cursor: pointer;
          line-height: 0.7;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 32px;
          min-width: 32px;
        }
        .first-last:hover {
          background: #38bdf8;
          color: #fff;
        }
        .disable-arrow {
          pointer-events: none;
          cursor: default;
          opacity: 0.4
        }
        .pagination-item-list {
          display: flex;
        }
        .pagination :global(.pagination-item) {
          padding: 8px;
          height: 32px;
          margin: auto 4px;
          color: rgba(0, 0, 0, 0.87);
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 13px;
          min-width: 32px;
        }
        .pagination :global(.pagination-item):hover {
          color: #fff;
          background: #38bdf8;
          font-weight: 600;
        }
        .pagination :global(.dots):hover {
          background-color: transparent;
          cursor: default;
          color: rgba(0, 0, 0, 0.87);
          font-weight: unset;
        }
        .pagination :global(.pagination-item.activePage) {
          color: #fff;
          background: #38bdf8;
          font-weight: 600;
        }
      `}</style>
    </>
  )
}

export default Pagination
