import React, { useState, useEffect, useContext } from 'react'
import { signIn } from 'next-auth/react'
import Head from 'next/head'
import Layout from '../layouts/legendofrocks/login'
import isEmail from 'validator/lib/isEmail'
import useNotification from '../hooks/useNotification'
import { useRouter } from 'next/router'
import MadMintThemeContext from '../contexts/MadMint'
import useDetectWindowMode, { WINDOW_MODE } from '../hooks/useDetectWindowMode'

type Props = {
  className?: string
}

export const Login: React.FC<Props> = ({ className }) => {
  const router = useRouter()
  const notifier = useNotification()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [googleLoading, setGoogleLoading] = useState(false)
  const { theme } = useContext(MadMintThemeContext)
  const windowMode = useDetectWindowMode()
  const isLargeScreen = [WINDOW_MODE['3XL']].includes(windowMode) // screen size is greater than 1920px

  const {
    query: { error },
  }: any = router

  const errors: any = {
    OAuthAccountNotLinked:
      'The Single sign on failed. You might have previously used the same email via the login form. Please try again using the login form',
    default: 'Unable to sign in.',
  }

  useEffect(() => {
    if (email.trim()) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [email])

  const handleEmailChange = (e: any) => {
    e.preventDefault()
    setEmail(e.target.value)
  }

  const handleSignUp = async (e: any) => {
    e.preventDefault()
    if (!isEmail(email)) {
      return notifier({
        message: 'Email is invalid',
        style: 'error',
      })
    }
    setLoading(true)
    signIn('email', {
      email,
      callbackUrl: `${window.location.origin}/dashboard`,
    })
  }

  const handleGoogleSignUp = async (e: any) => {
    e.preventDefault()
    setGoogleLoading(true)
    signIn('google', { callbackUrl: `${window.location.origin}/dashboard` })
  }

  useEffect(() => {
    if (error) {
      const errorMessage = error && (errors[error] ?? errors.default)
      notifier({
        message: errorMessage,
        style: 'error',
      })
      router.replace('/login')
    }
  }, [error])

  return (
    <Layout page="login">
      <Head>
        <title>Login</title>
          </Head>
          <div className='grid content-start md:content-center gap-8'>
              <div className="grid gap-12">
                    <div className="text-4xl xl:text-6xl text-[#E1E6E1] font-goldman-bold leading-tight 2xl:text-[80px]">
                        <span className="text-[#FF4BFE]">THE JOURNEY</span> <br />STARTS HERE
                    </div>
            </div>
            <form action="POST" className="md:max-w-[350px] lg:max-w-[454px] w-full">
                <div className='grid gap-9'>
                    <label className="section-header hidden">Email</label>
                    <input type="email" name='email' required={true} value={email} placeholder='Your Email Address' onChange={handleEmailChange} className='email-input text-[24px] w-full' />
                    <button onClick={handleSignUp} type="submit" disabled={loading} className="button transition duration-200 hover:bg-[rgb(255_93_254)] p-3 text-[#010304] bg-[#FF4BFE] text-lg lg:text-xl 2xl:text-[22px] rounded-lg font-goldman-regular w-full">{loading && email ? 'Loading...' : 'JOIN THE WAITLIST'}</button>
                    <div className="font-jost-bold text-[20px] text-[#5E5E5E]/50 text-center or-section relative">Or</div>
                    <button onClick={handleGoogleSignUp} type="submit" disabled={googleLoading} className="flex items-center gap-5 p-4 text-[#E1E6E1] border border-[#E1E6E1] rounded-lg justify-center text-lg lg:text-xl 2xl:text-[22px] font-goldman-regular transition duration-200 hover:border-[#FF4BFE]">
                        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M29.0733 14.0219H27.9993V13.9667H15.9993V19.2896H23.5347C22.4353 22.3882 19.4813 24.6125 15.9993 24.6125C11.5813 24.6125 7.99935 21.0375 7.99935 16.6281C7.99935 12.2188 11.5813 8.64374 15.9993 8.64374C18.0387 8.64374 19.894 9.41157 21.3067 10.6658L25.078 6.90181C22.6967 4.6868 19.5113 3.3208 15.9993 3.3208C8.63602 3.3208 2.66602 9.27916 2.66602 16.6281C2.66602 23.9771 8.63602 29.9355 15.9993 29.9355C23.3627 29.9355 29.3327 23.9771 29.3327 16.6281C29.3327 15.7359 29.2407 14.8649 29.0733 14.0219Z" fill="#FFC107"/>
                            <path d="M4.2041 10.4342L8.58477 13.6406C9.7701 10.7117 12.6408 8.64374 16.0001 8.64374C18.0394 8.64374 19.8948 9.41157 21.3074 10.6658L25.0788 6.90181C22.6974 4.6868 19.5121 3.3208 16.0001 3.3208C10.8788 3.3208 6.43743 6.2065 4.2041 10.4342Z" fill="#FF3D00"/>
                            <path d="M15.9999 29.9356C19.4439 29.9356 22.5732 28.6202 24.9392 26.481L20.8125 22.9958C19.4739 24.0079 17.8099 24.6127 15.9999 24.6127C12.5319 24.6127 9.58722 22.4057 8.47788 19.3257L4.12988 22.6692C6.33655 26.9787 10.8179 29.9356 15.9999 29.9356Z" fill="#4CAF50"/>
                            <path d="M29.074 14.0213H28V13.9661H16V19.289H23.5353C23.0073 20.7774 22.048 22.0609 20.8107 22.9958C20.8113 22.9951 20.812 22.9951 20.8127 22.9944L24.9393 26.4796C24.6473 26.7444 29.3333 23.2812 29.3333 16.6275C29.3333 15.7353 29.2413 14.8643 29.074 14.0213Z" fill="#1976D2"/>
                        </svg>
                        <span>CONTINUE WITH GOOGLE</span>
                    </button>
                </div>
            </form>
          </div>
          <style jsx>{`
          input {
            border-radius: 8px;
            padding: 16px 24px;
            color: #E1E6E1;
            background: rgba(10, 10, 10, 0.8);
            border: 1px solid #5E5E5E;
          }
          input:focus {
            outline: none;
            shadow: none;
          }
          button:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      .email-input::placeholder {
        font-family: 'Jost-Light';
        color: rgba(225, 230, 225, 0.5);
      }
      .or-section:after,
      .or-section:before {
        content: '';
        position: absolute;
        width: calc((100% - 64px) / 2);
        border: 1px solid rgba(94, 94, 94, 0.5);
        top: 50%;
      }
      .or-section:before {
        left: 0;
      }
      .or-section:after {
        right: 0;
      }
      `}</style>
    </Layout>
  )
}
