import ProjectEntries from '../../../components/Projects/Entries'
import Layout from '../../../components/Layout'

type Props = {
  slug: string
  className?: string
}

export const ProjectEntriesPage: React.FC<Props> = ({ className, slug }) => {
  return (
    <Layout className={className}>
      <ProjectEntries slug={slug} />
      <style jsx>{``}</style>
    </Layout>
  )
}
