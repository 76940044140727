import React, { useContext } from 'react'
import { signOut } from 'next-auth/react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import MadMintThemeContext from '../contexts/MadMint'
import Layout from '../layouts/legendofrocks/login'

type Props = {
  className?: string
}

export const Logout: React.FC<Props> = ({ className }) => {
  const router = useRouter()
  const { theme } = useContext(MadMintThemeContext)

  const handleSignOut = async () => {
    signOut({ redirect: false }).then(() => {
      router.push('/login')
    })
  }

  return (
    <Layout page="login">
      <Head>
        <title>Logout</title>
      </Head>
      <div className="flex login-sceen">
        <div>
          <div className="topwrapper flex items-center justify-center">
            <div>
              <div className="wrapper text-center">
                <h1 className="text-white font-bold">Sign Out</h1>
                <p className="py-3 text-white text-small">
                  Are you sure you want to sign out?
                </p>
              </div>
              <div className="relative">
                <div className="forms">
                  <button
                    className="button text-small"
                    onClick={handleSignOut}
                    type="submit"
                  >
                    <span>Sign Out</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="nfts-image-section">
          <img src="/images/legendsofrock/nfts2.png" alt="nfts" className="nft-image" />
        </div>
      </div>
      <style jsx>{`
          .font-bold {
            font-family: "tt trailers";
            font-style: normal;
            font-weight: 800;
            font-size: ${theme.sizes.largeHeading.h3.fontSize}px;
            line-height: ${theme.sizes.largeHeading.h3.lineHeight}px;
            text-transform: uppercase;
            color: ${theme.colors.lightBlack};
          }
          .button {
            border-radius: 8px;
            padding: 16px 24px;
            width: 338px;
            height: 52px;
            background: ${theme.colors.orange};
            color: ${theme.colors.lightBlack};
          }
          .button span {
            letter-spacing: 0.02em;
            align-items: center;
            text-align: center;
          }
          .login-sceen {
            padding: 30px 230px;
            justify-content: space-around;
          }
          .text-small {
            font-size: ${theme.sizes.content.p4.fontSize}px;
            line-height: ${theme.sizes.content.p4.lineHeight}px;
            font-style: normal;
            font-weight: 500;
            font-family: "Poppins-Regular";
          }
          button:disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }
          @media screen and (max-width: 1280px) {
            .login-sceen {
              padding: 30px 65px;
            }
          }
          @media screen and (max-width: 1023px) {
            .login-sceen {
              padding: 30px;
            }
            .font-bold {
              font-size: 48px;
            }
            .nft-image {
              width: 310px;
            }
            .nfts-image-section {
              display: flex;
              align-items: center;
            }
          }
          @media screen and (max-width: 767px) {
            .login-sceen {
              flex-direction: column;
              align-items: center;
            }
            .nfts-image-section {
              padding-top: 25px;
            }
          }
        `}</style>
    </Layout>
  )
}
